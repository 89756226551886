import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaCopy, FaPlus } from "react-icons/fa6";
import { toast } from "react-toastify";
import { httpRequest } from "../utils/httpRequest";
import { BASE_URL } from "../config";
import { CartContext } from "../context/CartContext";

import { CgCopy } from "react-icons/cg";
import { BiCopy } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { paymentRequest } from "../utils/payment";

const CheckoutForm = ({ packing, total }) => {
  const [states, setStates] = useState();
  const [cities, setCities] = useState();
  const [payinfo, setpayinfo] = useState();
  const [checked, setchecked] = useState(false);

  const navigate = useNavigate();

  const { cart, setCart } = useContext(CartContext);

  async function getData() {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      const newData = await httpRequest(
        "GET",
        "/user/info/" + user?._id,
        {},
        { "x-access-token": user?.token }
      ).then(({ data }) => {
        const values = Object.keys(data.data.user);

        values.map((item) => {
          formik.setFieldValue(item, data.data.user[item]);
        });
      });
    }
    await fetch("https://iran-locations-api.ir/api/v1/fa/states").then(
      async (res) => {
        const data = await res.json();
        setStates(data);
      }
    );
  }

  useEffect(() => {
    getData();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      address: "",
      state: "",
      city: "",
      postalCode: "",
      allPrice: total,
      products: [],
      userId: "",
    },

    onSubmit: async (values) => {
      const user = JSON.parse(localStorage.getItem("user"));
      values.userId = user?._id;
      cart?.map((e) => {
        values.products.push({
          productId: e.id,
          count: e.count,
          price: e?.special_price ? e?.special_price : e?.price,
        });
      });
      httpRequest("POST", "/order", values, {}).then(async (e) => {
        if (e?.status == 201) {
          localStorage.setItem("amount", total);
          localStorage.setItem("orderId", e.data.data._id);
          paymentRequest(total, e.data.data._id);
        } else {
          const message = await e.json();
          toast.error(message?.message);
        }
      });
    },
  });

  async function getCitis() {
    await fetch(
      "https://iran-locations-api.ir/api/v1/fa/cities?state=" +
        formik?.values?.state
    ).then(async (res) => {
      const data = await res.json();
      if (formik?.values?.state) {
        setCities(data[0].cities);
      } else {
        setCities(data);
      }
    });
  }
  useEffect(() => {
    if (!formik.values.city) {
      getCitis();
    }
  }, [formik.values.state]);
  return (
    <form
      onSubmit={formik.handleSubmit}
      dir="rtl"
      class="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0"
    >
      <p class="text-xl font-medium">اطلاعات خرید</p>
      <p class="text-gray-400">اطلاعات خود را برای سفارش کامل کنید</p>

      <div class="">
        <div className="grid md:grid-cols-2 gap-2">
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              نام و نام خانوادگی
            </label>
            <div class="relative">
              <input
                type="text"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              شماره تماس
            </label>
            <div class="relative">
              <input
                type="text"
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>

          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              کد پستی
            </label>
            <div class="relative">
              <input
                type="text"
                id="postalCode"
                name="postalCode"
                value={formik.values.postalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>

          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              استان
            </label>
            <div class="relative">
              <select
                id="state"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              >
                <option></option>
                {states?.map((e) => (
                  <option>{e.name}</option>
                ))}
              </select>
            </div>
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              شهر
            </label>
            <div class="relative">
              <select
                id="city"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.values.state === ""}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              >
                <option></option>
                {cities?.map((e) => (
                  <option>{e.name}</option>
                ))}
              </select>
            </div>
          </span>
          <span className="col-span-full">
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              آدرس
            </label>
            <div class="relative">
              <textarea
                type="text"
                id="address"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>

          <span className="col-span-full">
            <p>
              از داده های شخصی شما برای پردازش سفارش شما ، پشتیبانی از تجربه شما
              در سراسر این وب سایت و برای اهداف دیگری که در سیاست حفظ حریم خصوصی
              ما شرح داده شده استفاده خواهد شد.
            </p>
            <span className="flex gap-2 mt-4 items-start">
              <p>من شرایط و مقررات سایت را خوانده ام و آن را می پذیرم</p>
              <input
                onClick={() => setchecked(!checked)}
                checked={checked}
                type="checkbox"
                className="mt-2"
              />
            </span>
          </span>
        </div>
      </div>
      <button
        type="submit"
        class="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white"
      >
        ارسال سفارش
      </button>
    </form>
  );
};

export default CheckoutForm;
