import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import ProductCard from "./ProductCard";
import { httpRequest } from "../utils/httpRequest";
import { useState } from "react";
import { useEffect } from "react";
const SliderHeader = ({category}) => {
  const [products, setProducts] = useState([]);
  

  async function getProducts() {
    const response = await httpRequest("GET", `/product?limit=6&&category=${category}`, {}, {}).then((e) => {
      console.log(e);
      if (e.status === 200) {
        setProducts(e?.data?.data);
      }
    });
  }
  useEffect(() => {
    getProducts();
  }, []);
  return (
    <div
      
      className="mt-0  py-6"
    >
      <h2 className="border-sec w-full text-center lg:text-2xl text-xl font-bold ">
        {" "}
        محصولات مشابه
      </h2>
      <Swiper
        modules={[Autoplay]}
        breakpoints={{
          0: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          480: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          800: {
            slidesPerView: 4,
            spaceBetween: 0,
          },
        }}
        autoplay={{ delay: 2000 }}
        loop
        className="mySwiper "
      >
        {products.map((e) => (
          <SwiperSlide className=" relative">
            <ProductCard data={e} slider={true} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SliderHeader;
