import React, { useContext, useEffect, useState } from "react";

import { FaBox, FaGift } from "react-icons/fa6";
import CheckoutForm from "../components/CheckoutForm";
import { CartContext } from "../context/CartContext";

import CheckoutPrice from "../components/CheckoutPrice";
import { Helmet } from "react-helmet";

const CheckoutPage = () => {
  const [loading, setloading] = useState();
  const [productData, setproductData] = useState();

  const { cart, setCart } = useContext(CartContext);
  const total = cart.reduce((accumulator, current) => {
    return accumulator + current.price * current.count
}, 0);
  // if (loading) {
  //   return <Loading />;
  // }
  return (
    <div className="hero_area">
      <Helmet>
        <title>{"آریانا پوشاک | تسویه حساب"}</title>
        <meta name="og:title" content={"آریانا پوشاک | تسویه حساب"} />
      </Helmet>
      <div className=" overflow-hidden  py-10  bg-white text-right">
        <div class="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
          <div class="px-4 ">
            <CheckoutPrice checkoutPage total={total}/>
          </div>

          <CheckoutForm total={total}/>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
