import React from "react";
import { useParams } from "react-router-dom";
import CategorySlider from "../components/CategorySlider";
import NewestProduct from "../components/NewestProduct";
import WhySection from "../components/WhySection";
import { Helmet } from "react-helmet";

const ProductCategory = () => {
  const { name } = useParams();
  return (
    <div className="lg:px-6 px-2">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`آریانا پوشاک | ${name}`}</title>
        <meta name="og:title" content={`آریانا پوشاک | ${name}`} />
      </Helmet>
      <CategorySlider />
      <h2 className="border-sec w-full text-center lg:text-[20px] text-[16px] px-[100px] font-bold ">
        {" "}
        جدیدترین محصولات {name}
      </h2>
      <NewestProduct name={name} />
      <WhySection />
    </div>
  );
};

export default ProductCategory;
