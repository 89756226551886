import React from "react";

import AboutSection from "../components/AboutSection";
import TeamComponent from "../components/TeamSection";
import WhySection from "../components/WhySection";
import { Helmet } from "react-helmet";

const Aboutpage = () => {
  return (
    <div className="hero_area">
      <Helmet>
        <title>{"آریانا پوشاک | درباره ما"}</title>
        <meta name="og:title" content={"دکتر گلد | درباره ما"} />
      </Helmet>
      <div className="">
        <AboutSection />
        {/* <TeamComponent /> */}
        {/* <Banners/> */}
        <div className="my-10">
          <WhySection />
        </div>
      </div>
    </div>
  );
};

export default Aboutpage;
