import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";

const Paginate = ({page,setPage,pageCount}) => {
  
  function handlePagechange(e) {
    setPage(e.selected+1);
    console.log(page)
  }

  return (
    <ReactPaginate
      previousLabel={"<<"}
      nextLabel={">>"}
      pageCount={Math.ceil(pageCount/10)}
      onPageChange={handlePagechange}
      pageRangeDisplayed={4}
      containerClassName={"paginationbttn"}
      disabledClassName={"disabledbttn"}
      previousLinkClassName={"previousbttn"}
      nextLinkClassName={"nextbttn"}
      activeClassName={"activebttn"}
    />
  );
};

export default Paginate;
