import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { Link, useSearchParams } from "react-router-dom";
import { httpRequest } from "../utils/httpRequest";
import { FaTimes } from "react-icons/fa";

const VerifyPage = () => {
  const [searchParmas] = useSearchParams();
  const [data, setData] = useState();
  const [error, seterror] = useState();
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const authority = searchParmas.get("Authority");
    const status = searchParmas.get("Status");
    const amount = localStorage.getItem("amount");
    const orderId = localStorage.getItem("orderId");
    if (status == "NOK") {
      seterror(true);
      await httpRequest(
        "POST",
        "/order/pay/" + orderId,
        { status: "Fail" },
        {}
      );
    }

    await httpRequest(
      "POST",
      "/product/check_payment",
      { authority: authority, amount },
      {}
    )
      .then(async (res) => {
        setData(res.data.data);
        await httpRequest(
          "POST",
          "/order/pay/" + orderId,
          { status: "Success" },
          {}
        );
      })
      .catch(async (err) => {
        seterror(true);
        await httpRequest(
          "POST",
          "/order/pay/" + orderId,
          { status: "Fail" },
          {}
        );
      });
  }

  return (
    <div className="hero_area">
      <div className="min-h-[80vh] flex items-center">
        {error ? (
          <>
            <div className="w-full flex flex-col text-center px-3 items-center justify-center gap-4 py-10">
              <FaTimes
                size={45}
                color="white"
                className="bg-red-500 p-2 rounded-full"
              />
              <p>تراکنش ناموفقیت آمیز</p>
              <p>
                اگر مبلغی از حساب کم شده باشد تا 72 ساعت آینده بازگشته میشود
              </p>
              <Link
                to={"/"}
                className="btn bg-black text-white px-6 py-3 rounded-[8px]"
              >
                خانه
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="w-full flex flex-col text-center px-3 items-center justify-center gap-4 py-10">
              <FaCheck
                size={45}
                color="white"
                className="bg-green-500 p-2 rounded-full"
              />
              <p>تراکنش موفقیت آمیز</p>
              <p>
                اطلاعات خرید با پیامک برای شما ارسال میشود با رفتن به پنل نیز
                میتوانید آن را پیگیری کنید
              </p>
              <Link
                to={"/"}
                className="btn bg-black text-white px-6 py-3 rounded-[8px]"
              >
                خانه
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyPage;
