import React from "react";
import { FaInstagram, FaTelegram, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import Input from "../components/Input";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  return (
    <div
      style={{ backgroundImage: "url(/banner_service.webp)" }}
      className="px-2 lg:px-24  xl:px-[200px] mt-5 relative z-10 bg-no-repeat bg-right mb-10"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"آریانا پوشاک | ارتباط با ما"}</title>
        <meta name="og:title" content={"آریانا پوشاک | ارتباط با ما"} />
      </Helmet>
      <div className="max-w-[600px] mx-auto">
        <h6 class="mb-4 flex justify-center  font-semibold text-[18px] uppercase ">
          راه هایه ارتباطی با ما
        </h6>
        <p class="mb-4 gap-2 flex items-center justify-end">
          شیراز،خیابان حضرتی(سردزک)پاساژ شهر،طبقه بالا،تولیدی اریانا، پلاک118
          <span class=" [&>svg]:h-6 [&>svg]:w-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
              <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
            </svg>
          </span>
        </p>
        <Link
          to="tel:"
          dir="ltr"
          class="mb-4 flex items-center justify-end md:justify-end"
        >
          0913 000 0000
          <span class="ms-3 [&>svg]:h-5 [&>svg]:w-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
        </Link>
        <div class="flex flex-col justify-end items-end gap-5">
          <Link
            target="_blank"
            class=" flex items-center justify-end md:justify-end gap-2"
          >
            <p>telegram me</p>
            <FaTelegram size={26} color={"#000"} />
          </Link>
          <Link
            target="_blank"
            class=" flex items-center justify-end md:justify-end gap-2"
          >
            <p>Whatsapp me</p>
            <FaWhatsapp size={26} color={"#000"} />
          </Link>
          <Link
            to={"http://instagram.com/t_ariyana"}
            target="_blank"
            class="flex items-center justify-end md:justify-end gap-2"
          >
            <p>t_ariyana</p>
            <FaInstagram size={26} color={"#000"} />
          </Link>
        </div>
      </div>
      <Input />
    </div>
  );
};

export default ContactPage;
