import React from "react";

import { Link } from "react-router-dom";

const AboutSection = () => {
  return (
    <section
      style={{ backgroundImage: "url(/img/bg.jpg)" }}
      class="bg-right lg:bg-center bg-cover"
    >
      <div class=" bg-black/50  py-10">
        <div class="grid lg:grid-cols-2 grid-cols-1 text-right lg:mx-[100px] mx-6 ">
          <div class="felx order-2 lg:order-1 flex-col h-full justify-center items-center text-white">
            <h3 class="custom_heading text-[2rem]">شروع آریانا پوشاک</h3>

            <p class="">
              لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با
              استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله
              در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد
              نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد،
              کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
              جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای
              طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان
              فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری
              موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد
              نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل
              دنیای موجود طراحی اساسا مورد استفاده قرار گیرد..
            </p>

            <div>
              <Link to="/about">بیشتر</Link>
            </div>
          </div>

          <div class="w-full order-1 lg:order-2 flex justify-center">
            <img className="lg:w-[50%]" src="img/logo.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
