import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import ScrollToTop from "./utils/ScrooltoTop";
import { CartContext } from "./context/CartContext";
import "./app.css";
import "swiper/css";
import SignPage from "./pages/SignPage";
import LoginPage from "./pages/LoginPage";
import AccountPage from "./pages/AccountPage";
import ProductDetailPage from "./pages/ProductDetailPage";
import CartPage from "./pages/CartPage";
import CheckoutPage from "./pages/CheckoutPage";
import ProductCategory from "./pages/ProductCategory";
import Aboutpage from "./pages/Aboutpage";
import TermsPage from "./pages/TermsPage";
import ContactPage from "./pages/ContactPage";
import { ToastContainer } from "react-toastify";
import VerifyPage from "./pages/VerifyPage";
const App = () => {
  const [cart, setCart] = useState([]);
  return (
    <>
      <BrowserRouter>
        <CartContext.Provider value={{ cart, setCart }}>
        <ToastContainer position="top-center" />
          <Navbar />
          <ScrollToTop />
          
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<SignPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/dashboard" element={<AccountPage />} />
            <Route path="/product/:id" element={<ProductDetailPage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/category/:name" element={<ProductCategory />} />
            <Route path="/about" element={<Aboutpage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/verify" element={<VerifyPage />} />
          </Routes>
        </CartContext.Provider>

        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;
