import React from "react";
import { Helmet } from "react-helmet";

import { FaSquare } from "react-icons/fa";

const TermsPage = () => {
  return (
    <div className="lg:px-[100px] px-4">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"آریانا پوشاک | قوانین و مقررات"}</title>

        <meta name="og:title" content={"آریانا پوشاک | قوانین و مقررات"} />
      </Helmet>
      <div dir="rtl" class="bg-white text-black text-right">
        <div class="container mx-auto py-8 ">
          {" "}
          <div className="border-2 p-3 lg:p-6 rounded-lg shadow">
            <div className="relative  z-10 pb-8 lg:pt-10 flex-col items-center justify-center flex">
              <h1 className="text-center font-bold justify-center flex gap-2 items-center  text-[24px] lg:text-[36px] ">
                <FaSquare size={12} color="#F37324" />
                قوانین و مقررات
              </h1>
            </div>
            <div>
              <p dir="RTL" className="mb-[15px]">
                <span dir="RTL">
                  کلیه اصول و رویه های آریانا پوشاک منطبق با قوانین جمهوری
                  اسلامی ایران قانون تجارت الکترونیک و قانون حمایت از حقوق مصرف
                  کننده است و متعاقبا کاربر نیز موظف به رعایت قوانین مرتبط با
                  کاربر است .
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  توجه داشته باشید در صورتی که در قوانین مندرج رویه ها وسرویس
                  های آریانا پوشاک تغییراتی در اینده ایجاد شود در همین صفحه
                  منتشر و به روز رسانی میشود و شما توافق میکنید که استفاده مستمر
                  شما از سایت به معنی پذیرش هر گونه تغییر است .
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL" className="font-bold text-[20px]">
                  سیاست های رعایت حریم شخصی:
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  آریانا پوشاک برای خرید یا استفاده از برخی خدمات وب سایت
                  اطلاعاتی مانند شماره های تماس/ادرس و ...را از کاربران در خواست
                  می کند و متعهد میشود در حد توان از حریم شخصی شما دفاع کند و در
                  این راستا تکنولوژی مورد نیاز برای هر چه مطمن تر وامن تر شدن
                  استفاده شما از سایت را توسعه دهد. در واقع با استفاده از سایت
                  آریانا پوشاک شما رضایت خود را از این سیاست نشان میدهید.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  توجه داشته باشید همه مطالب در دسترس از طریق هر یک از خدمات
                  آریانا پوشاک مانند متن /گرافیک /ارم / ایکون دکمه/تصاویر
                  /ویدیوهای تصویری/مارد قابل دانلود وکپی داده های وکلیه محتوای
                  تولید شده توسط آریانا پوشاک جزیی از اموال آریانا پوشاک محسوب
                  میشود و حق استفاده ونشر تمامی مطالب موجود و در دسترس در انحصار
                  آریانا پوشاک است و هرگونه استفاده بدون کسب مجوز کتبی حق پیگرد
                  قانونی را برای آریانا پوشاک محفوظ میدارد.علاوه بر این اسامی
                  خدمات قابل ارایه از طریق هریک از خدمات ایجاد شده توسط آریانا
                  پوشاک وعلایم تجاری ثبت شده نیز در انحصار آریانا پوشاک است و هر
                  گونه استفاده با مقاصد تجاری پیگرد قانونی دارد.کاربران مجاز به
                  بهره برداری و استفاده از لیست محصولات مشخصات فنی قیمت و هر
                  گونه استفاده از مشتقات وب سایت آریانا پوشاک ویا هریک از خدمات
                  و یا مطالب دانلود یا کپی کردن اطلاعات با مقاصد تجاری هر گونه
                  استفاده از داده کاوی یا روبات یا روش های مشابه مانند جمع اوری
                  داده ها و ابزارهای استخراج نیستند و کلیه این حقوق به صراحت
                  برای آریانا پوشاک محفوظ است.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  در صورت استفاده از هریک از خدمات آریانا پوشاک کاربران مسول حفظ
                  محرمانه بودن حساب و رمز عبور خود هستند وتمامی مسولیت فعالیت
                  هایی که تحت حساب کاربری و یا رمز ورود انجام میپذیرد به عهده
                  کاربران است.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL" className="font-bold text-[20px]">
                  ثبت و ارسال سفارش:
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  روز کاری به معنی روز شنبه تا پنج شنبه هر هفته به استثنای
                  تعطیلات عمومی در ایران است و کلیه سفارش های ثبت شده در طول
                  روزهای کاری و اولین روز پس از تعطیلات پردازش میشوند.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  در صورت بروز مشکل در سبد خرید مانند انصراف مشتری مبلغ پرداخت
                  شده طی 48 ساعت کاری به حساب مشتری واریز خواهد شد.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  آریانا پوشاک مجاز است بدون اطلاع قبلی نسبت به توقف سفارش گیری
                  جدید اقدام و فروش خود را متوقف کند و کلیه سفارش های ثبت شده
                  قبل از توقف سفارش گیری پردازش و ارسال میشود.حق قطع فروش کلیه و
                  یا بخشی از محصولات به هر دلیلی مانند اتمام موجودی کالا بدون
                  اطلاع قبلی برای آریانا پوشاک محفوظ است.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  در صورت بروز هر گونه خطا نسبت به درج قیمت و ارزش ریالی کالا
                  های موجود در سایت آریانا پوشاک حق بلا اثر نمودن سفارش و خرید
                  انجام شده توسط مشتری برای آریانا پوشاک محفوظ است.آریانا پوشاک
                  در اسرع وقت وجوه دریافتی را به حساب اعلام شده توسط مشتری واریز
                  و عودت می نماید و مشتری با ورود به سایت آریانا پوشاک میپذیرد
                  از این امر اگاهی داشته و در این خصوص ادعایی نخواهد داشت.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  کاربران باید هنگام سفارش کالای مورد نظر خود فرم سفارش را با
                  اطلاعات صحیح و به طور کامل تکمیل کنند.بدیهی است در صورت ورود
                  اطلاعات ناقص یا نادرست سفارش کاربر قابل پیگری و تحویل نخواهد
                  بود.بنابراین درج ادرس و ایمیل و شماره تماس های همراه و ثابت
                  توسط مشتری به منزله مورد تایید بودن صحت انها است و در صورتی که
                  این موارد به صورت صحیح یا کامل درج نشده باشد آریانا پوشاک جهت
                  اطمینان از صحت و قطعیت ثبت سفارش میتواند از مشتری اطلاعات
                  تکمیلی و بیشتری در خواست کند.همچنین مشتریانمیتوانند نام /ادرسو
                  تلفن شخص دیگری را برای تحویل گرفتن سفارش وارد کنند و اگر مبلغ
                  سفارش از پیش پرداخت شده باشد تحویل گیرنده سفارش هنگام دریافت
                  کالا باید کارت شناسایی همراه داشته باشد.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  با توجه به ثبت سیستمی سفارش به هیچ عنوان امکان صدور فاکتور
                  مجدد یا تغییر مشخصات ان از جمله تغییر فاکتور حقیقی به حقوقی
                  وجود ندارد بنابر این لازم است مشتریان هنگام ثبت سفارش نسبت به
                  این مساله دقت لازم را داشته باشند.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  تحویل سفارش در اماکن عمومی امکان پذیر نمیباشد و لازم است ادرس
                  تحویل دقیق و قابل استناد باشد.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  آریانا پوشاک متعهد است پس از ثبت سفارش و ارسال تاییدیه به
                  مشتری با رعایت سیاست های معین ارسال کالا(از جمله هزینه های
                  مربوطه)سفارش را در مکان و زمان مورد نظر تحویل دهد. در صورت عدم
                  حضور سفارش دهنده در مکان و زمان تعیین شده خریدار موظف است برای
                  تحویل مجدد هزینه های مربوط را پرداخت نماید.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  تمامی شرایط و قوانین مندرج در شرایط عادی قابل اجراست و در صورت
                  بروز هر گونه حوادث غیرمترقبه اتفاقات غیر قابل پیش بینی و خارج
                  از اراده آریانا پوشاک هیچ گونه مسولیتی ندارد.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  آریانا پوشاک خود را ملزم به رعایت حریم شخصی کاربران میداند
                  لطفا در صورت مشاهده هرگونه تخلف مراتب را از طریق کانال های
                  ارتباطی ذکر شده با ما در میان بگذارید.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL" className="font-bold text-[20px]">
                  خسارت در هنگام حمل ونقل:
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  آریانا پوشاک نهایت تلاش خود را میکند تا کلیه سفارش ها در نهایت
                  صحت و بدون اسیب به دست مشتریان خود در سراسر کشور برسد و کلیه
                  خسارت ها در صورت بروز هر گونه حادثه هنگام حمل ونقل به عهد
                  آریانا پوشاک بوده و در راستای تسهیل امور پیگری خسارت را جبران
                  میکند.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL" className="font-bold text-[20px]">
                  سرویس مهلت تست آریانا پوشاک:
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  در راستای ایجاد سرویس های ارزش افزوده آریانا پوشاک این امکان
                  را ایجاد نموده تا کلیه کالا ها شامل 7 روز گارانتی استردادبدون
                  قید و شرط پس از تحویل باشند.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  لازم به توجه است که ایرادهای اعلام شده توسط مشتری توسط
                  کارشناسان خدمات پس از فروش آریانا پوشاک کنترل و بررسی میشود
                  ودر صورت تایید وجود نقص فنی کالا مشمول استفاده از سرویس فوق
                  میشود .همچنین این سرویس اشکال های فنی که در اثر استفاده نادرست
                  کاربر از کالا ایجاد شود را شامل نمیشود.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  برای استفاده از سرویس تضمین استرداد 7 روزه آریانا پوشاک دقت
                  فرمایید که کالا به خوبی بسته&nbsp;بندی شود لوازم جانبی و
                  فاکتور به صورت کامل به همراه ان ارسال شود و در صورتیکه که کالا
                  بهمراه لیبل برای مشتری ارسال شده است به هیچ عنوان نباید لیبل
                  از محصول جدا و یا مخدوش شده باشد .
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  در صورتی که کالای برگشتی در شرایط اولیه خود توسط آریانا پوشاک
                  دریافت نشود امکان سرویس دهی وجود ندارد و کالا خارج از شرایط
                  استرداد محسوب میشود.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  در صورتی که موجودی کالای اسیب دیده در آریانا پوشاک به اتمام
                  برسد و آریانا پوشاک امکان تعویض کالای اسیب دیده با کالای
                  جایگزین را نداشته باشد میتواند مبلغ کالا را به حساب خریدار
                  استرداد نماید و نوسان های قیمت باعث کاهش و یا افزایش این مبلغ
                  نمی شود.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  در صورتی که موجودی کالای اسیب دیده در آریانا پوشاک به اتمام
                  برسد مدت زمان اماده شدن مجدد ان بین 2تا 3 هفته کاری میباشد ودر
                  صورت اصراف مبلغ کالا به حساب خریدار استرداد داده میشود.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  در صورت عدم اگاهی اطلاع و ناتوانی مشتری در استفاده از محصولات
                  ویا ایجاد خسارت نسبت به محصول هزینه های ان برعهده مشتری است و
                  آریانا پوشاک در این خصوص هیچگونه تعهدی نخواهد داشت.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL" className="font-bold text-[20px]">
                  محتوا:
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  آریانا پوشاک نهایت تلاش ودقت را در راستای ارایه تمامی سرویس
                  های خود میکند لازم به ذکر است آریانا پوشاک تضمین نمیکند که
                  توصیفات محصول و یا دیگر مطالب مندرج در سایت عاری از خطا باشد.
                  اگر محصول ارایه شده توسط آریانا پوشاک دارای هر گونه مغایرت با
                  اطلاعات درج شده در سایت است تنها راه حل استرداد کالا قبل از
                  استفاده و در شرایط اولیه است.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  آریانا پوشاک هیچ گونه مسولیتی را در رابطه با حذف شدن صفخه های
                  سایت خود ویا لینک های مرده نمیپذیرد.سرویسهای سایت ان گونه که
                  هست ارایه میشود و تحت هیچ شرایطی مسولیت تاخیر یا عدم کارکرد
                  سایت را که می تواند ناشی از عوامل طبیعی نیروی انسانی مشکلات
                  اینترنتی خرابی تجهیزات کامپیوتری مخابراتی وغیره باشد بر عهده
                  ندارد.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL" className="font-bold text-[20px]">
                  سیاست قیمت گذاری:
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                <span dir="RTL">
                  سیاست قیمت گذاری در آریانا پوشاک مبتنی بر اصول مشتری مداری و
                  احترام به حقوق مصرف کننده است .قیمت های ارایه شده قیمت های
                  خرده فروشی و به صورت مصوب تعیین شده است.
                </span>
              </p>

              <p dir="rtl" className="mb-[15px]">
                &nbsp;
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;
