import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { IoHomeOutline } from "react-icons/io5";
import { useState } from "react";
import Burgers from "./Burgers";
import CartModal from "./CartModal";
import SignModal from "./SignModal";
import { useContext } from "react";
import { CartContext } from "../context/CartContext";
import { Link } from "react-router-dom";
import { useEffect } from "react";
const Navbar = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [modal, setModal] = useState(0);
  const { cart, setCart } = useContext(CartContext);

  useEffect(()=>{
    if (cart?.length) {
      setModal(1)
    }
  },[cart])
  return (
    <>
      <div className="grid grid-cols-5 fixed lg:static z-30 top-0 left-0 right-0 bg-gray-100 border-b shadow shadow-[#000000]/30 px-3 lg:px-10 overflow-x-hidden ">
        <div className="col-span-2 order-2 lg:order-1  hidden lg:flex justify-start items-center gap-5">
          <button
            onClick={() => setModal(1)}
            className="flex relative justify-start items-center gap-2 "
          >
            <span>0تومان</span>
            <span className="absolute top-0 right-0 bg-red-600 h-3 w-3 text-white z-10 rounded-full text-[10px] pt-[6px] flex justify-center items-center ">
              {cart.length}
            </span>
            <HiOutlineShoppingBag size={25} />
          </button>
          <button onClick={() => setModal(2)}>
            <AiOutlineUser size={25} />
          </button>
        </div>
        <Link to={"/"} className="flex justify-center order-1 lg:order-2">
          <img className="w-[100px]" src="/img/logo.png" alt="" />
        </Link>
        <Burgers
          show={show}
          show2={show2}
          setShow={setShow}
          setShow2={setShow2}
          setModal={setModal}
        />

        <span
          className={`bg-[#FFF] h-[120%] fixed w-full overflow-hidden lg:w-[300px] z-30 duration-300 ${
            modal >= 1 ? "left-[0%]" : "left-[-100%]"
          }`}
        >
          {modal === 1 && <CartModal setModal={setModal} />}
          {modal === 2 && <SignModal setModal={setModal} />}
        </span>
      </div>
      <div className=" bg-gray-100  fixed bottom-0 left-0 right-0 z-20 grid-cols-2 grid lg:hidden justify-items-center">
        <button
          onClick={() => setModal(1)}
          className="p-3 flex flex-col w-full h-full items-center justify-center "
        >
          <div className="relative">
            <span className="absolute top-0 right-0 bg-red-600 h-3 w-3 text-white z-10 rounded-full text-[10px] pt-[6px] flex justify-center items-center ">
              {cart.length}
            </span>
            <HiOutlineShoppingBag size={25} />
          </div>
          <span>سبد خرید</span>
        </button>
        <Link
          className="p-3  flex flex-col w-full h-full items-center justify-center"
          to={"/"}
        >
          <IoHomeOutline size={25} />

          <div>خانه</div>
        </Link>
      </div>
      {modal >= 1 && (
        <span className="fixed top-0 bottom-0 left-0 right-0 bg-[#000000]/30 z-20 "></span>
      )}
    </>
  );
};

export default Navbar;
