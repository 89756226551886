import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import { httpRequest } from "../utils/httpRequest";
import { useEffect } from "react";
import { useState } from "react";
import { CDN_BASE_URL } from "../config";
import Loading from "./Loading";
const CategorySlider = () => {
  const [loading, setLoading] = useState(true);
  const [categorys, setCategorys] = useState([]);
  async function getCategory() {
    setLoading(true)
    const response = await httpRequest("GET", "/category", {}, {}).then((e) => {
      setCategorys(e?.data?.data);
      setLoading(false)
    });
  }

  useEffect(() => {
    
    getCategory();
    
  }, []);
  if (loading) {
    return <Loading h={'60px'}/>;
  }
  return (
    <div className="mt-10 ">
      <Swiper
        modules={[Autoplay]}
        breakpoints={{
          0: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          480: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
          800: {
            slidesPerView: 6,
            spaceBetween: 30,
          },
        }}
        autoplay={{ delay: 5000 }}
        loop
        className="mySwiper"
      >
        {categorys.map((e) => (
          <SwiperSlide className="flex justify-center items-center ">
            <Link
              to={`/category/${e.name}`}
              className="grid grid-cols-1 justify-items-center"
            >
              <img
                className="lg:w-[130px] lg:h-[130px] w-[80px] h-[80px] object-cover rounded-full border-[#BB910E] border-[3px]"
                src={CDN_BASE_URL + e.img}
                alt=""
              />
              <p className="text-center mt-2 font-bold lg:text-[16px] text-[9px]">
                {e.name}
              </p>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CategorySlider;
