import React from "react";
import { useContext } from "react";
import { MdOutlineClose } from "react-icons/md";
import { TbShoppingBagX } from "react-icons/tb";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../config";
import { CartContext } from "../context/CartContext";
import { priceComma } from "../utils/priceComma";

const CartModal = ({ setModal }) => {
  const { cart, setCart } = useContext(CartContext);

  function handleDelete(e) {
    const newCart = cart.filter((c) => c.id !== e.id);
   
    setCart(newCart);
  }
  return (
    <div className="w-full relative flex flex-col items-center justify-between ">
      <div className="flex justify-between w-full items-center border-b py-4 px-3 mb-8">
        <button
          onClick={() => {
            setModal(0);
          }}
          className="flex items-center "
        >
          <p className="mb-1">بستن</p>
          <MdOutlineClose size={25} />
        </button>
        <span className="text-xl ">سبد خرید</span>
      </div>
      {cart.length ? (
        <div className="flex flex-col w-full">
          {cart.map((e) => (
            <div
              dir="rtl"
              className="flex relative w-full gap-5 justify-start items-start border-b p-3"
            >
              <img className="w-[50px]" src={CDN_BASE_URL + e.img} alt="" />
              <div className="flex flex-col text-[12px]">
                <span className="font-bold">{e.name}</span>
                <span>
                  <span>{e.color}</span>
                  <span>{e.size}</span>
                 
                </span>
                <span>{e.count}عدد</span>
                <span>{priceComma(e.price,2)}تومان</span>
              </div>
              <button
                onClick={() => handleDelete(e)}
                className="absolute top-1 left-1"
              >
                <MdOutlineClose size={20} color={"#999"} />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <>
          <TbShoppingBagX size={130} color={"#8f8f8f"} />
          <p>سبد خرید شما خالی میباشد</p>
        </>
      )}
      <Link onClick={()=>setModal(0)} to={"/cart"} className="mt-5 w-[90%] text-black rounded-3xl text-center py-3 bg-gray-100 ">
        مشاهده سبد خرید
      </Link>
      <Link onClick={()=>setModal(0)} to={"/checkout"} className="mt-5 w-[90%] text-white rounded-3xl text-center py-3 bg-[#c2a200] ">
        تسویه حساب
      </Link>
    </div>
  );
};

export default CartModal;
