import { useFormik } from "formik";
import React, { useEffect } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { httpRequest } from "../utils/httpRequest";
import { toast } from "react-toastify";
import * as Yup from "yup";

const SignModal = ({ setModal }) => {
  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      password: Yup.string().required("الزامی است"),
      phone: Yup.string()
        .min(11, "شماره موبایل صحیح نیست")
        .max(11, "شماره موبایل صحیح نیست")
        .required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: { phone: "", password: "" },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      await httpRequest("POST", "/user/login", values, {})
        .then((data) => {
          toast.success("موفقیت آمیز");
          formik.resetForm();
          window.location.href = window.origin + "/dashboard";
          localStorage.setItem("user", JSON.stringify(data.data?.data));
        })
        .catch((data) => {
          toast.error(data?.response?.data?.message);
          formik.resetForm();
        });
    },
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user?.token) {
      window.location.href = window.origin + "/dashboard";
    }
  }, []);
  return (
    <div className="w-full flex flex-col items-center">
      <div className="flex justify-between w-full items-center border-b py-4 px-3">
        <button
          onClick={() => {
            setModal(0);
          }}
          className="flex items-center "
        >
          <p className="mb-1">بستن</p>
          <MdOutlineClose size={25} />
        </button>
        <span className="text-xl ">ورود</span>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        dir="rtl"
        className="p-4 flex flex-col gap-5 border-b"
      >
        <span>
          <label htmlFor="">شماره موبایل</label>
          <input
            className="w-full px-4 h-10 outline-none border rounded-2xl shadow mt-1 "
            id="phone"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autoComplete="phone"
          />
        </span>
        <span className="">
          <label htmlFor="">رمز عبور</label>
          <input
            className="w-full px-4 h-10 outline-none border rounded-2xl shadow mt-1 "
            id="password"
            name="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autoComplete="current-password"
          />
        </span>
        <button className="w-full bg-[#c2a200] text-white rounded-2xl py-2 mt-6">
          ورود
        </button>
      </form>
      <div className="flex flex-col items-center pt-7">
        <AiOutlineUser size={130} color={"#8f8f8f"} />
        <p>حساب کاربری ندارید؟</p>
        <Link onClick={()=>setModal(0)} to={"/signup"} className="mt-5 underline text-[18px]">
          ایجاد حساب کاربری
        </Link>
      </div>
    </div>
  );
};

export default SignModal;
