import React, { useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { httpRequest } from "../utils/httpRequest";
const Input = () => {
  
  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      text: Yup.string().required("الزامی است"),
      email: Yup.string().required("الزامی است"),
    });
  };
  const formik = useFormik({
    initialValues: {
      text: "",
      email: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      await httpRequest(
        "POST",
        "/category/email",
        { email: values.email, content: values.text },
        {}
      ).then((e) => {
        if (e.status === 201) {
          console.log("sucsess");
          toast.success("sucsessful");
          formik.resetForm();
        }
      });
    },
  });
  return (
    <div dir="rtl" className="max-w-[600px] py-16 mx-auto">
      
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col justify-around items-center m-2 my-4 shadow-lg rounded-lg bg-white/90 p-3">
            <div className="flex flex-col justify-center items-center py-2">
              <h1 className="font-bold text-xl">پیامتان را بگذارید</h1>
              <p className="text-[17px]">ما با شما تماس خاهیم گرفت</p>
            </div>
            <textarea
              name="text"
              cols="30"
              rows="5"
              className="w-full h-[100px] m-2 bg-gray-200 p-2 border-none "
              placeholder="پیام شما"
              type="text"
              id={"text"}
              value={formik.values.text}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            ></textarea>
            <div className="flex w-full py-2">
              <input
                className="w-full p-2 bg-gray-200 border-none"
                type="email"
                placeholder="ایمیلتان را وارد بکنید"
                value={formik.values.email}
                id="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="email"
              />
              <button className="bg-[#c2a200] text-white rounded-md px-3 py-1 text-lg whitespace-nowrap">
                 ارسال
              </button>
            </div>
          </div>
        </form>
      
    </div>
  );
};

export default Input;
