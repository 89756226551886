import { httpRequest } from "./httpRequest";

export async function paymentRequest(amount, id) {
  await httpRequest("POST", "/product/payment", { amount, id }, {}).then(
    (res) => {
      if (res.status === 201) {
        window.location.href =
          "https://www.zarinpal.com/pg/StartPay/" + res.data.data.authority;
      }
    }
  );
}
