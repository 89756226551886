import React from "react";

const WhySection = () => {
  return (
    <section dir="rtl" className="  mb-10 lg:px-10 px-2">
      <div className=" ">
        <h3 className=" text-[1.75rem]">چرا آریانا پوشاک؟</h3>

        <p className=" mt-1 lg:text-[18px] text-[15px]  ">
          انتخاب کردن فروشگاه آریانا پوشاک به معنایه بهره مندی از با کیفیت ترین
          پوشاک طبق استاندارد ها هست
        </p>
      </div>
      <div className="">
        <div className="grid grid-cols-1 lg:grid-cols-2  gap-3 mt-5">
          <div className="flex justify-start items-start gap-4 w-full mt-5">
            <div className="border shadow   rounded">
              <img
                className="lg:max-w-[100px] max-w-[60px] lg:p-4"
                src="/images/express.png"
                alt=""
              />
            </div>
            <div className="flex flex-col items-start col-span-3">
              <h3 className="font-bold lg:text-[18px] text-[14px]">
                تحویل سریع
              </h3>
              <p className="lg:text-[16px] text-[12px] ">
                ما در اریانا پوشاک متعهد به تحویل سریع محصولات هستیم تا نیازهای
                شما را به‌موقع و با کیفیت بالا برآورده کنیم.
              </p>
            </div>
          </div>
          <div className="flex justify-start items-start gap-4 w-full mt-5">
            <div className="border shadow   rounded">
              <img
                className="lg:max-w-[100px] max-w-[60px]  lg:p-4"
                src="/images/24-hours.png"
                alt=""
              />
            </div>
            <div className="flex flex-col items-start col-span-3">
              <h3 className="font-bold lg:text-[18px] text-[14px]">
                پشتیبانی شبانه روزی
              </h3>
              <p className="lg:text-[16px] text-[12px]">
                تیم پشتیبانی شبانه‌روزی همواره در دسترس است تا به سوالات و
                نیازهای شما پاسخ دهد و اطمینان حاصل کند که خدمات ما در هر لحظه
                در دسترس شماست .
              </p>
            </div>
          </div>
          <div className="flex justify-start items-start gap-4 w-full mt-5">
            <div className="border shadow   rounded">
              <img
                className="lg:max-w-[100px] max-w-[60px] lg:p-4"
                src="/images/free-delivery.png"
                alt=""
              />
            </div>
            <div className="flex flex-col items-start col-span-3">
              <h3 className="font-bold lg:text-[18px] text-[14px]">
                ارسال رایگان
              </h3>
              <p className="lg:text-[16px] text-[12px]">
                استفاده از خدمات آریانا  از ارسال رایگان در سراسر کشور بهره‌مند شوید تا تجربه‌ای راحت و
                بدون دغدغه داشته باشید .
              </p>
            </div>
          </div>
          <div className="flex justify-start items-start gap-4 w-full mt-5">
            <div className="border shadow   rounded">
              <img
                className="lg:max-w-[100px] max-w-[60px] lg:p-4"
                src="/images/online-payment.png"
                alt=""
              />
            </div>
            <div className="flex flex-col items-start col-span-3">
              <h3 className="font-bold lg:text-[18px] text-[14px]">
              پرداخت آنلاین
              </h3>
              <p className="lg:text-[16px] text-[12px]">
                ما در اریانا پوشاک متعهد به تحویل سریع محصولات هستیم تا نیازهای
                شما را به‌موقع و با کیفیت بالا برآورده کنیم.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhySection;
