import React from "react";
import { useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { CiSearch } from "react-icons/ci";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link } from "react-router-dom";
import BurgerMobile from "./BurgerMobile";
import { FaCircle } from "react-icons/fa6";
import { httpRequest } from "../utils/httpRequest";
import { useEffect } from "react";
const Burgers = ({ show, show2, setShow, setShow2, setModal }) => {
  const [burger, setBurger] = useState(false);
  const [categorys, setCategorys] = useState([]);
  async function getCategory() {
    const response = await httpRequest("GET", "/category", {}, {}).then((e) => {
      setCategorys(e?.data?.data);
    });
  }

  useEffect(() => {
    getCategory();
  }, []);
  return (
    <div className="flex order-3 text-[16px] font-[500] lg:justify-around justify-end gap-5 lg:gap-0 items-center col-span-4 lg:col-span-2">
      <button className="lg:mr-4" onClick={() => setBurger(!burger)}>
        <CiSearch size={25} />
      </button>
      <button className="lg:hidden" onClick={() => setModal(2)}>
        <AiOutlineUser size={25} />
      </button>
      <button onClick={() => setBurger(!burger)} className="lg:hidden ">
        <RxHamburgerMenu size={25} />
      </button>

      <div className="lg:grid grid-cols-4 justify-items-center h-full w-full ml-6 hidden ">
        {" "}
        <Link className="my-auto" to={"/terms"}>قوانین سایت</Link>
        <Link className="my-auto" to={"/about"}>درباره ما</Link>
        <Link className="my-auto" to={"/contact"}>تماس با ما</Link>
        <button className="cate" onClick={() => setShow(!show)}>
          <span>دسته بندی</span>

          <div className="grid  grid-cols-3 absolute top-[30px] w-[0px]  duration-300 opacity-0  h-0 overflow-hidden right-5 z-20 justify-items-end px-4 py-2 bg-[#FFFFFF] shadow-sm shadow-[#000000]/20">
            {categorys.map((e) => (
              <Link to={`/category/${e?.url}`} className="hover:bg-gray-100 duration-100 flex justify-end items-center gap-2 py-2 px-3 w-full">
                {e?.name} <FaCircle color="#c2a200" size={10}/>
              </Link>
            ))}
          </div>
        </button>
      </div>

      <span
        className={`bg-[#FFF] top-0 lg:top-[100px] h-full w-full fixed  z-20 duration-300 ${
          burger ? "left-[-0%]  " : "left-[-105%] "
        }`}
      >
        <BurgerMobile categorys={categorys} setBurger={setBurger} />
      </span>
    </div>
  );
};

export default Burgers;
