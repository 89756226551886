import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../config";
import { httpRequest } from "../utils/httpRequest";
import { priceComma } from "../utils/priceComma";
import Loading from "./Loading";
import ProductCard from "./ProductCard";
const BurgerMobile = ({ setBurger, categorys }) => {
  const [active, setActive] = useState(0);
  const [value, setValue] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [empty, setEmpty] = useState(false);
  useEffect(() => {
    if (value !== "") {
      setLoading(true);
    }
    const timer = setTimeout(async () => {
      await httpRequest(
        "GET",
        "/product?limit=12&search_key=" + value,
        {},
        {}
      ).then(({ data }) => {
        setLoading(false);
        if (value === "") {
          return setProducts();
        }
        setProducts(data?.data);

        if (!data?.data?.length) {
          setEmpty(true);
        } else {
          setEmpty(false);
        }
      });
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [value]);

  return (
    <div className="flex flex-col items-center w-full overflow-y-auto h-[90vh] text-black ">
      <div
        onClick={() => setBurger(false)}
        className="flex justify-end items-center w-full p-4 shadow-md shadow-[#000000]"
      >
        <MdOutlineClose size={25} />
      </div>
      <div dir="rtl" className="w-full">
        <input
          className="w-full outline-none text-xl text-center p-2 border-b "
          type="text"
          placeholder="جستجویه محصولات"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>

      {loading ? (
        <Loading h={"100px"} />
      ) : products?.length ? (
        <div className="grid lg:grid-cols-6 grid-cols-1 w-full ">
          {products?.map((e) => (
            <Link
              onClick={() => setBurger(false)}
              to={`/product/${e?.url}`}
              dir="rtl"
              className="flex lg:flex-col relative w-full gap-5 justify-start items-start border-b p-3"
            >
              <img
                className="lg:w-[150px] w-[60px] h-[60px] lg:h-[150px] object-cover"
                src={CDN_BASE_URL + e?.images[0]}
                alt=""
              />
              <div className="flex flex-col text-[12px]">
                <span className="font-bold">{e.name}</span>

                <span>
                  {e?.special_price
                    ? priceComma(e?.special_price, 2)
                    : priceComma(e?.price, 2)}
                  تومان
                </span>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        value !== "" && empty && <span>هیچ محصولی پیدا نشد</span>
      )}
      <div className="grid grid-cols-2 justify-items-center w-full text-black/50  bg-gray-200 font-bold lg:hidden">
        <button
          onClick={() => setActive(0)}
          className={` w-full h-full py-6 border-b-2 ${
            !active && "bg-gray-300 border-[#000000] text-black"
          }`}
        >
          دسته بندی ها
        </button>
        <button
          onClick={() => setActive(1)}
          className={` w-full h-full py-6 border-b-2 ${
            active && "bg-gray-300 border-[#000000] text-black"
          }`}
        >
          منو
        </button>
      </div>
      {active === 0 && (
        <div className="flex  flex-col items-center w-full lg:hidden">
          {categorys.map((e) => (
            <Link
              onClick={() => setBurger(false)}
              to={`/category/${e.name}`}
              className="border-b py-4 px-4 w-full text-end"
            >
              {e.name}
            </Link>
          ))}
        </div>
      )}
      {active === 1 && (
        <div className="flex flex-col items-center w-full lg:hidden">
          <Link
            onClick={() => setBurger(false)}
            to={"/terms"}
            className="border-b py-4 px-4 w-full text-end"
          >
            قوانین سایت
          </Link>
          <Link
            onClick={() => setBurger(false)}
            to={"/contact"}
            className="border-b py-4 px-4 w-full text-end"
          >
            تماس با ما
          </Link>
          <Link
            onClick={() => setBurger(false)}
            to={"/about"}
            className="border-b py-4 px-4 w-full text-end"
          >
            درباره ما
          </Link>
          <Link
            onClick={() => setBurger(false)}
            to={"/dashboard"}
            className="border-b py-4 px-4 w-full text-end"
          >
            حساب کاربری من
          </Link>
        </div>
      )}
    </div>
  );
};

export default BurgerMobile;
