import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../config";
import { priceComma } from "../utils/priceComma";

const ProductCard = ({ data, slider }) => {
  const imgRef = useRef();
  const [colors, setColors] = useState([]);
  const [discount, setDiscount] = useState();
  const items = data?.products;
  function handleEnter() {
    if (data.images.length > 1) {
      if (imgRef.current) {
        imgRef.current.src = CDN_BASE_URL + data.images[1];
      }
    }
  }
  function handleLeave() {
    if (data.images.length > 1) {
      if (imgRef.current) {
        imgRef.current.src = CDN_BASE_URL + data.images[0];
      }
    }
  }

  function handlecolor() {
    const groupedByColor = items?.reduce((acc, item) => {
      if (!acc[item.color]) {
        acc[item.color] = [];
      }
      acc[item.color].push(item);
      return acc;
    }, {});

    // تبدیل به آرایه از اشیا گروه‌بندی شده
    if (groupedByColor && Object.keys(groupedByColor).length > 0) {
      const result = Object.entries(groupedByColor).map(([color, items]) => ({
        color,
        colorCode: items[0].colorCode,
      }));

      setColors(result);
    } else {
      console.error("No items grouped by color or groupedByColor is undefined");
    }
  }

  function calculateDiscount(originalPrice, discountedPrice) {
    if (originalPrice <= 0) {
      throw new Error("قیمت اصلی باید بزرگتر از صفر باشد.");
    }
    const discountAmount = originalPrice - discountedPrice;
    const discountPercentage = (discountAmount / originalPrice) * 100;
    return discountPercentage.toFixed(2); // درصد تخفیف را با دو رقم اعشار برمی‌گرداند
  }
  useEffect(() => {
    handlecolor();
    if (data?.special_price) {
      const dis = calculateDiscount(data?.price, data?.special_price);
      setDiscount(dis);
    }
  }, []);

  return (
    <div
      className={`border rounded-xl p-2 flex flex-col shadow-lg  ${
        slider
          ? "shadow-[#000]/40 m-1 my-4 lg:h-[400px] h-[200px] "
          : "shadow-[#000]/20 m-1"
      }`}
    >
      <Link
        to={`/product/${data?.url}`}
        className="relative z-10 inline-block overflow-hidden rounded-xl choose "
      >
        <img
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
          ref={imgRef}
          className="w-full object-cover lg:min-h-[330px] lg:max-h-[330px] h-[170px] md:h-[270px] rounded-xl hover:scale-110 duration-700 ease-out "
          src={CDN_BASE_URL + data.images[0]}
          alt=""
        />
        <span className="absolute bottom-[-40%] w-full text-center bg-[#c2a200]/60 overflow-hidden duration-200 py-1">
          انتخاب گزینه
        </span>
        {data?.special_price && (
          <span className="absolute top-4 px-4 pt-1 rounded-l-xl h-fit  right-0 bg-red-500 text-white">
            {discount}%
          </span>
        )}
      </Link>
      {!slider && (
        <div className="flex justify-center items-center gap-2 mt-2">
          {colors.map((e) => (
            <div className={` border-[#000000] flex pb-1`}>
              <button
                style={{ backgroundColor: e.colorCode }}
                className={`h-4 w-4 rounded-full shadow shadow-[#000000]/40  `}
              ></button>
            </div>
          ))}
        </div>
      )}
      <div className="w-full flex flex-col items-center mt-4">
        <Link className="font-[500]">{data?.name}</Link>
        <span className="text-[16px] font-bold flex ">
          تومان{" "}
          <p>
            {data?.special_price
              ? priceComma(data?.special_price, 2)
              : priceComma(data?.price, 2)}
          </p>{" "}
        </span>
      </div>
    </div>
  );
};

export default ProductCard;
