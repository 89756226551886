import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import ProductGallery from "../components/ProductGallery";
import SliderHeader from "../components/SliderHeader";
import { CartContext } from "../context/CartContext";
import { httpRequest } from "../utils/httpRequest";
import { priceComma } from "../utils/priceComma";
import { Helmet } from "react-helmet";
import { CDN_BASE_URL } from "../config";
const ProductDetailPage = () => {
  const images = ["/clothes/2.webp", "/clothes/14.jpg"];
  const [count, setCount] = useState(1);
  const { cart, setCart } = useContext(CartContext);
  const [color, setColor] = useState();
  const [size, setSize] = useState();
  const [data, setData] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [balance, setBalance] = useState();
  const [discount, setDiscount] = useState();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  async function getProduct() {
    setLoading(true);
    const response = await httpRequest("GET", `/product/${id}`, {}, {}).then(
      (e) => {
        console.log(e);
        if (e.status === 200) {
          setData(e?.data?.data);
          handlecolor(e?.data?.data?.subproducts);
          if (e?.data?.data?.product?.special_price) {
            const dis = calculateDiscount(
              e?.data?.data?.product?.price,
              e?.data?.data?.product?.special_price
            );
            setDiscount(dis);
          } else {
            setDiscount();
          }
          if (e?.data?.data?.product?.single) {
            setBalance(e?.data?.data?.product?.balance);
          }
        }
      }
    );
    setLoading(false);
  }
  useEffect(() => {
    getProduct();
  }, [id]);
  function calculateDiscount(originalPrice, discountedPrice) {
    if (originalPrice <= 0) {
      throw new Error("قیمت اصلی باید بزرگتر از صفر باشد.");
    }
    const discountAmount = originalPrice - discountedPrice;
    const discountPercentage = (discountAmount / originalPrice) * 100;
    return discountPercentage.toFixed(2); // درصد تخفیف را با دو رقم اعشار برمی‌گرداند
  }

  const { product, subproducts } = data;

  function handleAdd() {
    if (balance?.balance === 0 || product?.balance === 0) {
      return toast.warn("محصول موجود نمیباشد");
    }
    if (subproducts?.length) {
      if (!color || !size) {
        return toast.warn("لطفا قبل سفارش مشخصات را تایین کنید");
      }
      const newCart = {
        name: product?.name,
        color: color,
        size: size,
        img: product?.images[0],
        price: product?.special_price ? product?.special_price : product?.price,
        count: count,
        id: balance?._id,
      };
      setCart((prevCart) => {
        const cartExited = prevCart.find((e) => e.id === newCart.id);
        if (cartExited) {
          return prevCart.map((item) =>
            item.id === cartExited.id
              ? { ...item, count: item.count + newCart.count }
              : item
          );
        } else {
          return [...prevCart, newCart];
        }
      });
    } else {
      const newCart = {
        name: product?.name,

        img: product?.images[0],
        price: product?.special_price ? product?.special_price : product?.price,
        count: count,
        id: product?._id,
      };
      setCart((prevCart) => {
        const cartExited = prevCart.find((e) => e.id === newCart.id);
        if (cartExited) {
          return prevCart.map((item) =>
            item.id === cartExited.id
              ? { ...item, count: item.count + newCart.count }
              : item
          );
        } else {
          return [...prevCart, newCart];
        }
      });
    }
  }
  function handlecolor(e) {
    const groupedByColor = e?.reduce((acc, item) => {
      if (!acc[item.color]) {
        acc[item.color] = [];
      }
      acc[item.color].push(item);
      return acc;
    }, {});

    // تبدیل به آرایه از اشیا گروه‌بندی شده
    if (groupedByColor && Object.keys(groupedByColor).length > 0) {
      const result = Object.entries(groupedByColor).map(
        ([color, subproducts]) => ({
          color,
          colorCode: subproducts[0].colorCode,
        })
      );

      setColors(result);
    } else {
      console.error("No items grouped by color or groupedByColor is undefined");
    }
  }
  function handleChose(e) {
    setCount(1);
    if (color) {
      setSize();
      setBalance();
    }
    setColor(e.color);
    const sizes = subproducts.filter((c) => c.color === e.color);
    setSizes(sizes);
  }
  function handleBalance(e) {
    setCount(1);
    setSize(e?.size);
    const foundItem = subproducts.find(
      (item) => item.color === color && item.size === e?.size
    );

    setBalance(foundItem);
  }

  if (loading) {
    return <Loading h={"100px"} />;
  }

  return (
    <div className="mt-10 px-2 lg:px-4">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${product?.name ? product?.name : ""} | آریانا پوشاک`}</title>
        <meta
          name="og:title"
          content={`${product?.name ? product?.name : ""} | آریانا پوشاک`}
        />
        <meta name="og:image" content={CDN_BASE_URL + product?.images[0]} />
      </Helmet>
      <div className="grid lg:grid-cols-3 grid-cols-1 lg:px-10 px-4">
        <div
          dir="rtl"
          className="lg:col-span-2 col-span-1 order-2 lg:order-1 flex flex-col mt-5 lg:px-10 "
        >
          <h2 className="text-2xl font-bold">{product?.name}</h2>
          {product?.special_price ? (
            <div className="text-[20px] mt-5">
              <span className="line-through text-[16px] ml-2">
                {priceComma(product?.price, 2)}تومان
              </span>
              <span>{priceComma(product?.special_price, 2)}تومان</span>
            </div>
          ) : (
            <span className="text-[20px] mt-5">
              {priceComma(product?.price, 2)}تومان
            </span>
          )}
          {/* <p className="text-gray-600 mt-5">
            دور کمرتونو از روی ناف متر بزنید تقسیم بر دو کنید میشه سایزتون مثلا
            دور کمر ۸۰ سایزش ۴۰ میشه
          </p> */}
          <div>
            {product?.height && (
              <span className="flex mt-5">
                <p className="text-gray-600">{product?.height}</p>
              </span>
            )}
          </div>
          {subproducts?.length ? (
            <>
              <p className="font-bold mt-5 mb-1">رنگ ها:</p>
              <div className="felx justify-start items-center gap-3">
                {colors.map((e) => (
                  <span
                    className={` border-[#000000] pt-6 m-2 ${
                      e?.color === color ? "border-b-2" : "border-b-0"
                    }`}
                  >
                    <button
                      onClick={() => {
                        handleChose(e);
                      }}
                      style={{ backgroundColor: e?.colorCode }}
                      className={`h-7 w-7 rounded-full shadow shadow-[#000000]/40`}
                    ></button>
                  </span>
                ))}
              </div>
              <p className="font-bold mt-7">سایز ها:</p>
              <div className="felx justify-start items-center gap-3">
                {!sizes?.length && (
                  <p className="text-[12px] text-gray-400">
                    لطفا رنگ مورد نظر را انتخاب کنید
                  </p>
                )}
                {sizes.map((e) => (
                  <button
                    onClick={() => handleBalance(e)}
                    className={`border-2 duration-150  hover:border-[#000000] bg-gray-100 m-2 rounded-3xl py-2 px-5 ${
                      size === e?.size ? "border-[#000000]" : "border-gray-200"
                    } ${e?.balance === 0 && "sale"}`}
                  >
                    {e?.size}
                  </button>
                ))}
              </div>
            </>
          ) : (
            <span className="flex mt-5">
              <p className="text-gray-600">{product?.size}</p>
            </span>
          )}

          <div className="felx justify-start items-center gap-4  mt-5">
            <span className="py-1 w-fit items-center border rounded-3xl mx-4">
              <button
                onClick={() => {
                  if (product?.single) {
                    if (count === balance) {
                      return;
                    } else setCount(count + 1);
                  } else {
                    if (count === balance?.balance || !balance?.balance) {
                      return;
                    } else setCount(count + 1);
                  }
                }}
                className="border-l px-2 py-1 hover:bg-black hover:text-white rounded-r-3xl"
              >
                +
              </button>
              <span className="px-2 py-1">{count}</span>
              <button
                onClick={() => {
                  if (count === 1) {
                    return;
                  } else setCount(count - 1);
                }}
                className="border-r px-2 py-1 hover:bg-black hover:text-white rounded-l-3xl"
              >
                -
              </button>
            </span>
            <span>
              <button
                onClick={handleAdd}
                className="bg-[#c2a200] inline text-white px-6 py-2 rounded-3xl border border-[#fff] "
              >
                افزودن به سبد خرید
              </button>
            </span>
          </div>
          {product?.single ? (
            <p
              className={`text-[14px] duration-150 border rounded-2xl border-black bg-gray-200 w-fit px-4 py-1 mt-5 `}
            >
              موجودیه این کالا {balance} عدد
            </p>
          ) : (
            <p
              className={`text-[14px] duration-150 border rounded-2xl border-black bg-gray-200 w-fit px-4 py-1  ${
                balance ? "opacity-1 mt-5" : "opacity-0 mt-0"
              }`}
            >
              موجودیه این کالا {balance?.balance} عدد
            </p>
          )}
        </div>
        <div className=" w-full z-10 order-1 lg:order-2">
          <ProductGallery images={product?.images} discount={discount} />
        </div>
      </div>
      <div className="py-6">
        <h2 className="border-sec w-full text-center  lg:text-2xl text-xl font-bold ">
          توضیحات بیشتر
        </h2>
        <div
          dir="rtl"
          className="px-[1rem] lg:px-12"
          dangerouslySetInnerHTML={{ __html: product?.desc }}
        ></div>
      </div>

      {product && <SliderHeader category={product?.category[0]} />}
    </div>
  );
};

export default ProductDetailPage;
