import React from "react";
import CategorySlider from "../components/CategorySlider";
import NewestProduct from "../components/NewestProduct";
import WhySection from "../components/WhySection";

const Home = () => {
  return (
    <div className="lg:px-6 px-1">
      <CategorySlider />
      <h2 className="border-sec w-full text-center lg:text-2xl text-xl font-bold ">
        {" "}
        جدیدترین محصولات
      </h2>
      <NewestProduct />
      <WhySection/>
    </div>
  );
};

export default Home;
