import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { useFormik } from "formik";
import { httpRequest } from "../utils/httpRequest";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Helmet } from "react-helmet";

const LoginPage = () => {
  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      password: Yup.string().required("الزامی است"),
      phone: Yup.string()
        .min(11, "شماره موبایل صحیح نیست")
        .max(11, "شماره موبایل صحیح نیست")
        .required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: { phone: "", password: "" },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      await httpRequest("POST", "/user/login", values, {})
        .then((data) => {
          toast.success("موفقیت آمیز");
          formik.resetForm();
          window.location.href = window.origin + "/dashboard";
          localStorage.setItem("user", JSON.stringify(data.data?.data));
        })
        .catch((data) => {
          toast.error(data?.response?.data?.message);
          formik.resetForm();
        });
    },
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user?.token) {
      window.location.href = window.origin + "/dashboard";
    }
  }, []);

  return (
    <div className="hero_area">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"آریانا پوشاک | ورود کاربر"}</title>
     
        <meta name="og:title" content={"آریانا پوشاک | ورود کاربر"} />
      </Helmet>
      

      <div
        dir="rtl"
        className="py-12 flex items-center justify-center bg-white"
      >
        <div className="flex min-h-full flex-col justify-center px-6 py-6 border shadow-md shadow-[#000000]/30 rounded lg:w-[380px] w-full mx-4">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              alt="Your Company"
              src="/img/logo.png"
              className="mx-auto h-28 w-auto"
            />
            <h2 className="mt-1 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              ورود به پنل کاربری
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form
              onSubmit={formik.handleSubmit}
              className="space-y-6 text-right  "
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  شماره موبایل
                </label>
                <div className="mt-2">
                  <input
                    id="phone"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="phone"
                    class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  />
                  {formik.errors.phone && formik.touched.phone && (
                    <small className="text-danger" style={{ color: "red" }}>
                      {formik.errors.phone}
                    </small>
                  )}
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    رمز عبور
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="current-password"
                    class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  />
                  {formik.errors.password && formik.touched.password && (
                    <small className="text-danger" style={{ color: "red" }}>
                      {formik.errors.password}
                    </small>
                  )}
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-[#c2a200] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#c2a200]/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#e09145]"
                >
                  ورود
                </button>
              </div>
            </form>

            <p className="mt-10 text-center text-sm text-gray-500">
              اکانت نداری؟
              <Link
                to="/signup"
                className="font-semibold leading-6 text-[#c2a200] hover:text-[#c2a200]/90"
              >
                ثبت نام
              </Link>
            </p>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default LoginPage;
